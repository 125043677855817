<template>
  <div class="main-container-lr">
    <div class="container-l" style="overflow-y: scroll; display: block">
      <div class="tree-wrapper">
        <div class="tree-serach">
          <el-input
            size="small"
            v-model="treeSearch.name"
            placeholder="机构名称"
            clearable
          />
          <el-button
            type="primary"
            style="margin: 15px 0"
            class="el-icon-circle-plus-outline"
            @click="add"
          >
            新增机构
          </el-button>
        </div>
        <div>
          <tree
            ref="tree"
            :tree-type="'com'"
            :params="treeSearch"
            :filterText="treeSearch.name"
            :api-function="this.$api.sysManage.treeData"
            @treeEdit="handlerTreeEdit"
            @nodeClick="handlerNodeClick"
          />
        </div>
      </div>
    </div>
    <div class="container-r">
      <el-tabs v-model="editableTabsValue" type="card">
        <el-tab-pane
          :key="item.name"
          v-for="item in editableTabs"
          :label="item.title"
          :name="item.name"
        >
          <component
            v-if="editableTabsValue === item.content"
            :is="item.content"
            :tree-node-id="treeData.id"
            :tree-node-code="treeData.code"
          />
        </el-tab-pane>
      </el-tabs>
    </div>

    <add-institutional
      :dialogVisible.sync="dialogVisible"
      :dialog-type="dialogType"
      :tree-data="treeData"
      @update="updateTree"
      v-if="dialogVisible"
    />
  </div>
</template>

<script>
import institutional from './component/iucomponent/institutional.vue';
import user from './component/iucomponent/user.vue';
import tree from './component/tree.vue';
import addInstitutional from './component/iucomponent/addInstitutional.vue';
export default {
  name: 'institutionalUser',
  components: {
    institutional,
    user,
    tree,
    addInstitutional,
  },
  data() {
    return {
      treeSearch: {
        name: '',
      },
      editableTabsValue: 'institutional',
      editableTabs: [
        {
          title: '机构',
          name: 'institutional',
          content: 'institutional',
        },
        {
          title: '用户',
          name: 'user',
          content: 'user',
        },
      ],
      dialogVisible: false,
      dialogType: '',
      treeData: {},
    };
  },
  methods: {
    /**
     * 新增机构
     */
    add() {
      this.dialogType = 'add';
      this.dialogVisible = true;
    },
    /**
     * 编辑机构
     */
    handlerTreeEdit(treeData) {
      this.$api.sysManage.getAgencyById(treeData).then((res) => {
        this.dialogType = 'edit';
        this.treeData = res.data;
        this.treeData.id = res.data.agencyId;
        this.treeData.enabled = res.data.status === '0' ? true : false;
        this.treeData.regionArr = [
          res.data.province,
          res.data.city,
          res.data.county,
        ];
        this.treeData.agencyCode = parseInt(this.treeData.agencyCode);
        this.dialogVisible = true;
      });
    },
    /**
     * 删除机构
     */
    // handlerTreeRemove(treeData) {
    //   this.$msg.confirm('确认删除机构？').then(() => {
    //     this.$api.sysManage.removeAgency(treeData).then((res) => {
    //       if (res.code == 0) {
    //         this.updateTree();
    //         this.$message({
    //           message: res.msg,
    //           type: 'success',
    //         });
    //         this.dialogVisible = false;
    //       }
    //     });
    //   });
    // },
    /**
     * 树节点点击事件
     */
    handlerNodeClick(treeData) {
      this.treeData = treeData;
    },
    /**
     * 更新左侧树
     */
    updateTree() {
      this.$refs.tree.queryTreeData();
      this.treeData = {};
    },
  },
};
</script>
<style lang="scss" scoped>
.container-r {
  background: #f0f2f7;
}
.el-tabs {
  width: 100%;
  height: 100%;
  background: #fff;
}
::v-deep .el-tabs__content {
  height: calc(100% - 6%);
}
::v-deep .el-tab-pane {
  width: 100%;
  height: 100%;
}
.tree-wrapper {
  .tree-serach {
    border-bottom: 1px solid #cfd9e6;
  }
}
.pt-wrapper {
  padding: 15px;
}
::v-deep .search-wrap {
  margin-bottom: 10px;
}
</style>
