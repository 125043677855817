<template>
  <div class="pt-wrapper" style="overflow-y: scroll">
    <table-search
      :model="searchParams"
      label-width="80px"
      :isClear="true"
      :flex="'5'"
      @search="searchFn('search')"
      :isResetByPublic="false"
      @clearSearch="clearSearchFn"
    >
      <search-item default label="">
        <el-input
          size="small"
          v-model="searchParams.userName"
          placeholder="姓名"
          clearable
        />
      </search-item>
      <search-item default label="">
        <el-input
          size="small"
          v-model="searchParams.loginName"
          placeholder="用户名"
          clearable
        />
      </search-item>
      <search-item default label="">
        <el-select v-model="searchParams.status" placeholder="状态" clearable style="width: 100%">
          <el-option label="启用" value="0"> </el-option>
          <el-option label="禁用" value="1"> </el-option>
        </el-select>
      </search-item>
      <search-item default label="">
        <el-date-picker
          style="width: 100%"
          v-model="searchParams.createTime"
          type="date"
          placeholder="创建时间"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      </search-item>
    </table-search>
    <div class="table-opt">
      <el-button
        size="small"
        type="primary"
        @click="add"
        class="el-icon-circle-plus-outline"
      >
        新增
      </el-button>
      <el-button
        size="small"
        type="warning"
        @click="delUser()"
        class="el-icon-delete"
      >
        批量删除
      </el-button>
    </div>
    <pageTable
      ref="table"
      row-key="userId"
      :columns="columnList"
      :params="searchParams"
      :api-function="this.$api.sysManage.queryUserListData"
      @selectAll="selectAll"
      @select="select"
      :initFalse="true"
    >
      <template slot="status" slot-scope="scope">
        <!-- <span>{{ scope.row.status == '0' ? '启用' : '禁用' }}</span> -->
        <el-switch
          v-model="scope.row.status"
          active-color="#13ce66"
          active-value="0"
          inactive-value="1"
          @change="changeState($event, scope.row)"
        >
        </el-switch>
      </template>
      <template slot="options" slot-scope="scope">
        <div class="data-operate">
          <span class="op-blue" @click="eidt(scope.row)"> 编辑 </span>
          <span class="op-blue" @click="toOrder(scope.row)"> 订单配置 </span>
          <!-- <span class="op-yellow" @click="removeAgency(scope.row)"> 移出 </span> -->
          <span class="op-red" @click="del(scope.row)"> 删除 </span>
        </div>
      </template>
    </pageTable>
    <add-user
      :userData="userData"
      @update="searchFn"
      :dialogVisible.sync="dialogVisible"
      :dialogType="dialogType"
      v-if="dialogVisible"
    />
  </div>
</template>

<script>
import addUser from './addUser.vue';
export default {
  name: 'User',
  props: {
    treeNodeCode: {
      type: String,
      default: () => '',
    },
    treeNodeId: {
      type: [String, Number],
      default: () => '',
    },
  },
  components: {
    addUser,
  },
  data() {
    return {
      userData: {},
      searchParams: {
        loginName: '',
        userName: '',
        status: '',
        createTime: '',
        agencyName: '',
        roleId: '',
      },
      columnList: [
        {
          type: 'selection',
        },
        {
          label: '序号',
          type: 'index',
          width: 60,
        },
        {
          label: '用户名',
          prop: 'loginName',
        },
        {
          label: '姓名',
          prop: 'userName',
        },
        {
          label: '角色名称',
          prop: 'roleName',
        },
        {
          label: '所属机构',
          prop: 'agencyName',
        },
        {
          label: '管理机构',
          prop: 'parentName',
        },
        // {
        //   label: '机构管理',
        //   prop: 'agencyName2',
        // },
        {
          label: '手机号',
          prop: 'phonenumber',
        },
        {
          label: '邮箱',
          prop: 'email',
        },
        // {
        //   label: '状态',
        //   prop: 'status',
        // },
        {
          label: '状态',
          prop: 'status',
          show: 'template',
          width: '80',
        },
        {
          label: '创建时间',
          prop: 'createTime',
        },
        {
          label: '操作',
          prop: 'options',
          show: 'template',
          width: '180',
        },
      ],
      selectData: [],
      dialogType: '',
      dialogVisible: false,
      loginRole: '',
    };
  },
  watch: {
    treeNodeId(newVal) {
      this.searchParams.agencyId = newVal;
      this.searchFn('search');
    },
  },
  mounted() {
    if (this.treeNodeId) {
      this.searchParams.agencyId = this.treeNodeId;
    }
    this.searchFn('search');
  },
  methods: {
    changeState(callback, row) {
      var params = {
        userId: row.userId,
        roleIds: row.roleId,
        status: callback,
        loginName: row.loginName,
      };
      this.$api.sysManage.editUser(params).then((res) => {
        if (res.code === 0) {
          this.$message.success(res.msg);
          this.searchFn('edit');
        }
      });
    },
    searchFn(status) {
      this.$refs.table.getPageDataOfAction(status);
    },
    clearSearchFn() {
      this.searchParams = {
        userName: '',
        status: '',
        createTime: '',
        agencyId: this.treeNodeId,
      };
      this.$nextTick(() => {
        this.searchFn('search');
      });
    },
    add() {
      this.dialogType = 'add';
      this.dialogVisible = true;
    },
    eidt(row) {
      this.dialogType = 'edit';
      this.dialogVisible = true;
      this.userData = row;
    },
    delUser() {
      if (this.selectData.length === 0) {
        this.$message({
          message: '请先勾选数据',
          type: 'warning',
        });
        return;
      }
      let userIdList = []
      this.selectData.forEach(item => {
        userIdList.push(item.userId)
      })
      this.$msg.confirm('确认删除这些用户？').then(() => {
        this.$api.sysManage
          .removeUser({
            ids: userIdList.join(','),
          })
          .then((res) => {
            if (res.code == 0) {
              this.$message({
                message: res.msg,
                type: 'success',
              });
              this.searchFn('del');
            }
          });
      });
    },
    del(params) {
      this.$msg.confirm('确认删除用户？').then(() => {
        this.$api.sysManage
          .removeUser({
            ids: params.userId,
          })
          .then((res) => {
            if (res.code == 0) {
              this.$message({
                message: res.msg,
                type: 'success',
              });
              this.searchFn('del');
            }
          });
      });
    },
    selectAll(params) {
      this.selectData = params;
    },
    select(params) {
      this.selectData = params;
    },
    removeAgency(params) {
      if (params === 'more' && this.selectData.length === 0) {
        this.$message({
          message: '请先勾选数据',
          type: 'warning',
        });
        return;
      }
      this.$msg.confirm('确认移出用户？').then(() => {
        this.$api.sysManage
          .removeAgencyUser({
            ids:
              params === 'more'
                ? this.selectData.map((i) => i.userId).join()
                : params.userId,
          })
          .then((res) => {
            if (res.code === 0) {
              this.$message({
                message: res.msg,
                type: 'success',
              });
              this.selectData = [];
              this.searchFn('del');
            }
          });
      });
    },

    /**
     * 订单
     */
    toOrder(row) {
      this.$router.push({
        path: '/sysManage/userMember',
        query: row,
      });
    },
  },
};
</script>
