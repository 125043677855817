<template>
  <div class="pt-wrapper" style="overflow-y: scroll">
    <table-search
      :model="searchParams"
      label-width="80px"
      :isClear="true"
      :flex="'6'"
      @search="searchFn"
      @clearSearch="clearSearchFn"
    >
      <search-item default label="">
        <el-input
          size="small"
          v-model="searchParams.agencyName"
          placeholder="机构名称"
          clearable
        />
      </search-item>
      <search-item default label="">
        <regionSelect
          v-model="regionArr"
          :checkStrictly="true"
          :placeholderText="'所属地区'"
        />
      </search-item>
      <search-item default label="">
        <el-date-picker
          style="width: 100%"
          v-model="searchParams.updateTime"
          type="date"
          placeholder="更新时间"
          value-format="yyyy-MM-dd"
          clearable
        >
        </el-date-picker>
      </search-item>
      <search-item default label="">
        <el-select
          v-model="searchParams.contractStatus"
          placeholder="是否签约"
          clearable
        >
          <el-option
            v-for="item in contractStatuses"
            :key="item.type"
            :label="item.name"
            :value="item.type"
          >
          </el-option>
        </el-select>
      </search-item>
    </table-search>
    <pageTable
      ref="table"
      :columns="columnList"
      :params="searchParams"
      :api-function="this.$api.sysManage.queryAgencyListData"
      :initFalse="true"
    >
      <template slot="contractStatus" slot-scope="scope">
        <div class="data-operate">
          <span v-if="scope.row.contractStatus === 0">否</span>
          <span v-if="scope.row.contractStatus === 1">是</span>
        </div>
      </template>
      <template slot="provinceName" slot-scope="scope">
        <div class="data-operate">
          <span>
            {{
              `${scope.row.provinceName}${
                scope.row.cityName ? '-' + scope.row.cityName : ''
              }${scope.row.countyName ? '-' + scope.row.countyName : ''}` || ''
            }}
          </span>
        </div>
      </template>
      <template slot="options" slot-scope="scope">
        <div class="data-operate">
          <span class="op-blue" @click="toDetail(scope.row)"> 分配订单 </span>
        </div>
      </template>
    </pageTable>
  </div>
</template>

<script>
export default {
  name: 'Institutional',
  props: {
    treeNodeId: {
      type: [String, Number],
      default: () => '',
    },
  },
  data() {
    return {
      searchParams: {
        agencyName: '',
        province: '',
        updateTime: '',
        agencyId: '',
        city: '',
        county: '',
        contractStatus: '',
      }, //所属地区arr
      regionArr: [], //
      contractStatuses: [
        { name: '是', type: '1' },
        { name: '否', type: '0' },
      ],
      columnList: [
        {
          label: '序号',
          type: 'index',
          width: 60,
        },
        {
          label: '统一社会信用代码',
          prop: 'unicode',
          width: 180,
        },
        {
          label: '机构名称',
          prop: 'agencyName',
          width: 130,
        },
        {
          label: '所属地区',
          prop: 'provinceName',
          show: 'template',
          width: window.innerWidth >= 1920 ? 260 : 160,
        },
        {
          label: '管理机构',
          prop: 'parentName',
        },
        {
          label: '是否签约',
          prop: 'contractStatus',
          show: 'template',
        },
        {
          label: '更新时间',
          prop: 'updateTime',
        },
        {
          label: '操作',
          prop: 'options',
          show: 'template',
          width: '120',
        },
      ],
    };
  },
  watch: {
    treeNodeId(newVal) {
      this.searchParams.agencyId = newVal;
      this.searchFn();
    },
    regionArr(newVal) {
      this.searchParams.province = newVal[0] ? newVal[0] : '';
      this.searchParams.city = newVal[1] ? newVal[1] : '';
      this.searchParams.county = newVal[2] ? newVal[2] : '';
    },
  },
  mounted() {
    if (this.treeNodeId) {
      this.searchParams.agencyId = this.treeNodeId;
    }
    this.searchFn();
  },
  methods: {
    clearSearchFn() {
      this.regionArr = [];
    },
    searchFn() {
      // this.searchParams.agencyId = '';
      this.$refs.table.getPageDataOfAction('search');
    },
    // 详情
    toDetail(row) {
      this.$router.push({
        path: '/sysManage/institutionalMember',
        query: {
          type: 'agency',
          agencyCode: row.agencyCode,
          agencyName: row.agencyName,
        },
      });
    },
  },
};
</script>
