<template>
  <el-dialog
    :title="dialogType === 'edit' ? '编辑机构' : '新增机构'"
    :visible.sync="dialogFormVisible"
    :close-on-click-modal="false"
    @close="$emit('update:dialogVisible', false)"
    width="45%"
  >
    <div class="dialog-content">
      <el-form
        ref="formIns"
        :model="form"
        size="mini"
        :rules="rules"
        :label-position="'right'"
        label-width="auto"
      >
        <el-form-item label="统一社会信用代码" prop="unicode">
          <el-input v-model="form.unicode" clearable></el-input>
        </el-form-item>
        <el-form-item label="所属地区" prop="regionArr">
          <regionSelect
            @change="handelChanegRegion"
            v-model="form.regionArr"
            :checkStrictly="true"
          />
        </el-form-item>
        <el-form-item label="机构名称" prop="agencyName">
          <el-input v-model="form.agencyName" clearable></el-input>
        </el-form-item>
        <el-form-item label="管理机构" prop="parentName">
          <el-select
            ref="selectOrg"
            v-model="form.parentName"
            placeholder=""
            style="width: 100%"
            clearable
          >
            <el-option :value="form.parentId" style="height: auto">
              <tree
                class="institutional-tree"
                ref="tree"
                :tree-type="'form'"
                :params="{}"
                :api-function="this.$api.sysManage.treeData"
                @nodeClick="handlerNodeClick"
              />
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否签约" prop="parentName">
          <el-select
            v-model="form.contractStatus"
            style="width: 100%"
            placeholder="请选择是否签约"
            size="mini"
            filterable
          >
            <el-option
              v-for="item in contractStatuses"
              :key="item.type"
              :label="item.name"
              :value="item.type"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否启用" prop="enabled">
          <el-switch v-model="form.enabled"> </el-switch>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="$emit('update:dialogVisible', false)"
        >取 消</el-button
      >
      <el-button size="small" type="primary" @click="saveFn">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import tree from '../tree.vue';

export default {
  name: 'AddInstitutional',
  components: {
    tree,
  },
  props: {
    dialogVisible: {
      type: Boolean,
      require,
    },
    treeData: {
      type: Object,
      require,
    },
    dialogType: {
      type: String,
      require,
    },
  },
  data() {
    // 自定义统一社会信用代码
    var checkUcode = (rule, value, callback) => {
      const UcodeReg = /^[^_IOZSVa-z\W]{2}\d{6}[^_IOZSVa-z\W]{10}$/g;
      if (UcodeReg.test(value)) {
        callback();
      } else {
        callback(new Error('统一社会信用代码格式不正确，请重新输入!'));
      }
    };
    return {
      dialogFormVisible: this.dialogVisible,
      form: {
        unicode: '',
        agencyName: '',
        province: '',
        city: '',
        county: '',
        regionArr: [], //省市区三级联动
        parentName: '',
        parentId: '',
        enabled: false,
        regionCode: '',
        contractStatus: 1,
      },
      contractStatuses: [
        { name: '是', type: 1 },
        { name: '否', type: 0 },
      ],
      rules: {
        unicode: [
          { required: true, message: '请输入统一社会信用代码' },
          { validator: checkUcode, trigger: 'blur' },
        ],
        agencyName: [{ required: true, message: '请输入机构名称' }],
        regionArr: [{ required: true, message: '请输入所属地区' }],
        // parentName: [{ required: true, message: '请选择管理结构' }],
      },
    };
  },
  mounted() {
    //如果是编辑的话放数据
    if (this.dialogType == 'edit') {
      for (let key in this.form) {
        this.form[key] = this.treeData[key];
      }
    }
  },
  methods: {
    add() {
      // 判断所属地区选择到第几级
      if (this.form.regionArr.length == 1) {
        this.form.province = this.form.regionArr[0];
        this.form.regionCode = this.form.regionArr[0];
      } else if (this.form.regionArr.length == 2) {
        this.form.province = this.form.regionArr[0];
        this.form.city = this.form.regionArr[1];
        this.form.regionCode = this.form.regionArr[1];
      } else {
        this.form.province = this.form.regionArr[0];
        this.form.city = this.form.regionArr[1];
        this.form.county = this.form.regionArr[2];
        this.form.regionCode = this.form.regionArr[2];
      }
      this.form.status = this.form.enabled ? '0' : '1';
      this.$api.sysManage.addAgency(this.form).then((res) => {
        if (res.code == 0) {
          this.$message.success(res.msg);
          this.$emit('update:dialogVisible', false);
          this.$emit('update');
        } else {
          this.$emit('update:dialogVisible', false);
        }
      });
    },
    handelChanegRegion(val) {
      if (val.length) {
        this.form.province = val[0] || '';
        this.form.city = val[1] || '';
        this.form.county = val[2] || '';
      }
    },
    eidt() {
      this.form.agencyId = this.treeData.agencyId;
      // 0启用，1禁用
      this.form.status = this.form.enabled ? '0' : '1';
      this.$api.sysManage.editAgency(this.form).then((res) => {
        if (res.code == 0) {
          this.$message.success(res.msg);
          this.$emit('update:dialogVisible', false);
          this.$emit('update');
        }
      });
    },
    saveFn() {
      this.$refs.formIns.validate((valid) => {
        if (valid) {
          this.dialogType === 'edit' ? this.eidt() : this.add();
        }
      });
    },
    handlerNodeClick(treeData) {
      this.form.parentName = treeData.name;
      this.form.parentId = treeData.id;
      this.$refs.selectOrg.blur();
    },
  },
};
</script>
<style lang="scss" scoped>
.dialog-content {
  width: 70%;
  margin: 0 auto;
}
</style>
<style lang="scss">
.institutional-tree {
  .el-tree.el-tree--highlight-current {
    > .el-tree-node {
      display: block !important;
    }
  }
}
</style>
