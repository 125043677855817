<template>
  <el-dialog
    :title="dialogType === 'edit' ? '编辑用户' : '新增用户'"
    :visible.sync="dialogFormVisible"
    :close-on-click-modal="false"
    @close="$emit('update:dialogVisible', false)"
    width="40%"
  >
    <div class="dialog-content">
      <el-form
        ref="formUser"
        :model="form"
        size="mini"
        :rules="rules"
        :label-position="'right'"
        label-width="auto"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="用户名" prop="loginName">
              <el-input v-model="form.loginName" clearable :disabled="dialogType === 'edit'"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item  v-if="dialogType !== 'edit'" label="密码" prop="password" style="margin-bottom:0px;">
              <el-input
                type="password"
                v-model="form.password"
                clearable
              ></el-input>
              <!-- <span class="pwd-tips">注:密码至少为六位数</span> -->
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="所属机构" prop="agencyCode">
          <el-select
            ref="selectOrg"
            v-model="form.agencyName"
            placeholder=""
            style="width: 100%"
          >
            <el-option :value="form.agencyCode" style="height: auto">
              <tree
                ref="tree"
                class="institutional-tree"
                :tree-type="'form'"
                :params="{}"
                :api-function="this.$api.sysManage.treeData"
                @nodeClick="handlerNodeClick"
              />
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="管理机构" prop="parentName">
          <el-input v-model="form.parentName" clearable disabled></el-input>
        </el-form-item>
        <el-form-item label="角色" prop="roleId">
          <el-select
            v-model="form.roleName"
            placeholder=""
            @change="handleRole"
            clearable
          >
            <el-option
              v-for="item in roleList"
              :key="item.roleId"
              :label="item.roleName"
              :value="item.roleId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="姓名" prop="userName">
          <el-input v-model="form.userName" clearable></el-input>
        </el-form-item>
        <el-row>
          <el-col :span="12">
            <el-form-item label="性别" prop="sex">
              <el-radio-group v-model="form.sex">
                <el-radio-button label="男"></el-radio-button>
                <el-radio-button label="女"></el-radio-button>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="状态" prop="status">
              <el-radio-group v-model="form.status">
                <el-radio-button label="0">激活</el-radio-button>
                <el-radio-button label="1">禁用</el-radio-button>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="手机号" prop="phonenumber">
              <el-input v-model="form.phonenumber" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="邮箱" prop="email">
              <el-input v-model="form.email" clearable></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="12">
            <el-form-item label="有效期限">
              <el-date-picker
                @change="expirationDateOnChange"
                v-model="expirationDateArray"
                value-format="yyyy-MM-dd"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="$emit('update:dialogVisible', false)"
        >取 消</el-button
      >
      <el-button size="small" type="primary" @click="saveFn">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import tree from '../tree.vue';

export default {
  name: 'AddUser',
  components: {
    tree,
  },
  props: {
    dialogVisible: {
      type: Boolean,
      require,
    },
    userData: {
      type: Object,
      default: () => ({}),
    },
    dialogType: {
      type: String,
      require,
    },
  },
  data() {
    var isMobileNumber = (rule, value, callback) => {
      if (!value) {
        return new Error('请输入手机号码');
      } else {
        const reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/;
        const isPhone = reg.test(value);
        value = Number(value); // 转换为数字
        if (typeof value === 'number' && !isNaN(value)) {
          // 判断是否为数字
          value = value.toString(); // 转换成字符串
          if (value.length < 0 || value.length > 12 || !isPhone) {
            // 判断是否为11位手机号
            callback(new Error('手机号码格式如:138xxxx8754'));
          } else {
            callback();
          }
        } else {
          callback(new Error('请输入手机号码'));
        }
      }
    };
    var isPwd = (rule, value, callback) => {
      if (!value) {
        return new Error('请输入密码');
      } else {
        var reg = /^.{6,}$/;
        const isPwd = reg.test(value);
        if (!isPwd) {
          callback(new Error('请输入至少六位数的密码'));
        }
      }
    };
    return {
      dialogFormVisible: this.dialogVisible,
      roleList: [],
      expirationDateArray: [],
      form: {
        loginName: '',
        password: '',
        agencyName: '',
        parentName: '',
        roleName: '',
        agencyCode: '',
        roleIds: '',
        roleId: '',
        userName: '',
        sex: '男',
        status: '0',
        phonenumber: '',
        email: '',
        expireBeginDate:'',
        expireEndDate:''
      },
      roleIds: '',
      parentName: '',
      rules: {
        loginName: [{ required: true, message: '请输入用户名' }],
        password: [
          { required: true, message: '请输入密码' },
          { min: 6, message: "至少输入六位数密码", trigger: "blur" },
        ],
        agencyCode: [{ required: true, message: '请选择所属机构' }],
        userName: [{ required: true, message: '请输入姓名' }],
        roleId: [{ required: true, message: '请选择角色名称' }],
        phonenumber: [
          { required: true, message: '请输入手机号' },
          // { validator: isMobileNumber },
        ],
      },
    };
  },
  // watch: {
  //   userData: {
  //     deep: true,
  //     handler(val) {
  //       for (let key in this.form) {
  //         this.form[key] = val[key] || '';
  //       }
  //       this.form.roleIds = parseInt(this.form.roleIds.toString());
  //     },
  //   },
  // },

  methods: {
    expirationDateOnChange(val) {
      if(val) {
        this.form.expireBeginDate = val[0]
        this.form.expireEndDate = val[1]
      } else {
        this.form.expireBeginDate = ''
        this.form.expireEndDate = ''
      }
    },
    handleRole(val) {
      this.form.roleIds = val;
      this.form.roleId = val;
    },
    saveFn() {
      this.$refs.formUser.validate((valid) => {
        if (valid) {
          var url = this.dialogType === 'edit' ? 'editUser' : 'addUser';
          if (this.dialogType === 'edit') {
            this.form.userId = this.userData.userId;
            this.form.roleIds = this.form.roleIds
              ? this.form.roleIds
              : this.userData.roleIds;
          }
          this.$api.sysManage[url](this.form).then((res) => {
            if (res.code === 0) {
              this.$message.success(res.msg);
              this.$emit('update:dialogVisible', false);
             this.$emit('update', this.dialogType == 'edit' ? 'edit': 'search');
            }
          });
        }
      });
    },
    handlerNodeClick(treeData) {
      this.form.agencyName = treeData.name;
      this.form.agencyCode = treeData.code;
      this.form.parentName = treeData.pname;
      this.$refs.selectOrg.blur();
    },
  },
  mounted() {
    this.$api.sysManage
      .queryRoleListData({ pageNum: 1, pageSize: 1000 })
      .then((res) => {
        this.roleList = res.data.list;
      });
    //如果是编辑的话放数据
    if (this.dialogType == 'edit') {
      for (let key in this.form) {
        this.form[key] = this.userData[key] || '';
      }

      if(this.userData.expireBeginDate && this.userData.expireEndDate) {
        this.expirationDateArray = [this.userData.expireBeginDate, this.userData.expireEndDate]
      }

      //当前的roleIds使用roleId 后续可能会改成数组
      this.form.roleIds = this.form.roleId == null ? '' : this.form.roleId;
    }
  },
};
</script>
<style lang="scss" scoped>
.dialog-content {
  width: 90%;
  margin: 0 auto;
}
.pwd-tips{
  color: red;
  font-size: 10px;
}
.institutional-tree {
  .el-tree.el-tree--highlight-current {
    > .el-tree-node {
      display: block !important;
    }
  }
}
</style>
